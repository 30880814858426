import { send } from "../index";


export function getTagClassifyList(data, opts = {}) {
  return send({
    url: "/admin/tagClassify/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveTagClassify(data, opts = {}) {
  return send({
    url: "/admin/tagClassify/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateTagClassify(data, opts = {}) {
  return send({
    url: "/admin/tagClassify/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getTagClassifyDetail(data, opts = {}) {
  return send({
    url: "/admin/tagClassify/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function delTagClassify(data, opts = {}) {
  return send({
    url: "/admin/tagClassify/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getTagList(data, opts = {}) {
  return send({
    url: "/admin/baseTag/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveTag(data, opts = {}) {
  return send({
    url: "/admin/baseTag/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateTag(data, opts = {}) {
  return send({
    url: "/admin/baseTag/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getTagDetail(data, opts = {}) {
  return send({
    url: "/admin/baseTag/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function delTag(data, opts = {}) {
  return send({
    url: "/admin/baseTag/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUserTag(data, opts = {}) {
  return send({
    url: "/admin/baseUser/updateTag.do",
    method: "POST",
    data,
    ...opts,
  });
}


// export function pullOnShelvesTag(data, opts = {}) {
//   return send({
//     url: "/admin/tag/pullOnShelves.do",
//     method: "POST",
//     data,
//     ...opts,
//   });
// }

// export function pullOffShelvesTag(data, opts = {}) {
//   return send({
//     url: "/admin/tag/pullOffShelves.do",
//     method: "POST",
//     data,
//     ...opts,
//   });
// }
