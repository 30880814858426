<template>
    <div class="ui-labelModal">
        <!-- 新建标签组的弹框 -->
        <!-- <a-modal v-model:visible="showLabel" width="780px" destroyOnClose title="选择标签"> -->
            <div style="display: flex;justify-content: space-between;">
               <!-- <div style="padding-top: 8px;">
                   
                </div> -->
                <!-- <div>
                    <a-button type="info" @click="onLabelClose">取消</a-button>
                    <a-button type="primary" @click="onOk">确定</a-button>
                </div> -->
            </div>
            <div class="ui-labelModal ui-labelModal__list">
                <a-spin :spinning="loading">
					<a-form ref="formRef" :model="modelRef" @finish="onSearch">
						<a-row type="flex">
							<a-form-item name="title" label="标签名称">
								<a-input v-model:value="modelRef.title" placeholder="请输入"/>
							</a-form-item>
							<a-form-item name="classifyId" label="标签分类" style="margin-left: 30px;">
								<a-select style="width:180px;" v-model:value="modelRef.classifyId" placeholder="请选择">
									<a-select-option v-for="item in classifyList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
								</a-select>
							</a-form-item>
							<div style="flex: 1;text-align: right;">
								<a-button style="margin-right: 20px;" type="primary" html-type="submit">搜索</a-button>
								<a-button @click="resetBtn">重置</a-button>
							</div>
						</a-row>
						<a-row>
							<a-button v-permission="['member_label_list_addClassify']" type="primary" @click="onAddNewLabelGroup">
							    <Icon icon="PlusOutlined"/>
							    新建标签分类
							</a-button>
						</a-row>
					</a-form>
                    <div class="ui-labelGroup__item" v-for="(item, index) in labelList" :key="index">
                        <div class="ui-label__name">{{ item.classifyTitle }}
							<span v-permission="['member_label_list_editClassify']">
								<Icon style="padding-left: 10px;color:#1890ff;" icon="FormOutlined" @click="onEditClassify(item)"></Icon>
							</span>
							<span v-permission="['member_label_list_deleteClassify']">
								<Icon style="padding-left: 4px;color:#ff4d4f;" icon="DeleteOutlined" @click="onDelClassify(item)"></Icon>
							</span>
						</div>
                        <div class="ui-label__box">
                            <a-button v-permission="['member_label_list_addLabel']" class="ui-tag" ghost type="primary" @click="onEdit(item)">
                                <Icon icon="PlusOutlined"/>
                                添加
                            </a-button>
                            <a-tag class="ui-tag"
                                   v-for="(subItem, subIndex) in item.tagList" :key="subItem.id" >
								   {{ subItem.title }} 
								   <span v-permission="['member_label_list_edit']">
									   <Icon style="padding-left: 10px;color:#1890ff;" icon="FormOutlined" @click="onEdit(item, subItem)"></Icon>
								   </span>
								   <span v-permission="['member_label_list_delete']">
									   <Icon style="color:#ff4d4f;" icon="DeleteOutlined" @click="onDel(item, subItem)"></Icon>
								   </span>
                            </a-tag>
                        </div>
                    </div>
					<!-- <div style="margin-top: 20px;text-align: right;">
					 <a-pagination
					      v-model:current="pagination.current"
					      v-model:page-size="pagination.pageSize"
					      :total="pagination.totalCount"
						  showSizeChanger
						  showQuickJumper
					      :show-total="total => `总共 ${total} 条`"
						  @change="onPageChange"
						  @showSizeChange="onShowSizeChange"
					    />
						</div> -->
                </a-spin>
            </div>
        <!-- </a-modal> -->

        <!-- 新建标签组弹窗 -->
        <a-modal :title="editClassify.id ? '编辑标签分类' : '新建标签分类'" width="600px" v-model:visible="showLabelGroup"
                 @ok="onAddLabelGroupAddOk">
            <div class="ui-labelModal">

                <div class="ui-form__item">
                    <span class="ui-form__label">分类名称:</span>
                    <a-input style="width: 310px;"
                             v-model:value="editClassify.title"
                             placeholder="请输入标签分类名称"></a-input>
                </div>
				
				<div class="ui-form__item">
				    <span class="ui-form__label">排序:</span>
					<div>
						<a-input-number
								v-model:value="editClassify.sort"
								:min="0"
								placeholder="请输入"></a-input-number>
						<div style="color: #999;">不填写默认为0(数字越小越靠前，0除外)</div>
					</div>
				</div>
            </div>
        </a-modal>
		
		<a-modal :title="(editLabel.id ? '编辑' : '新建') + '标签'" width="600px" v-model:visible="showLabelEdit" @ok="onLabelAddOk">
			<div class="ui-form__item">
			    <span class="ui-form__label">标签名称:</span>
			    <a-input style="width: 310px;"
			             v-model:value="editLabel.title"
			             placeholder="请输入标签名称"></a-input>
			</div>
		</a-modal>
    </div>
</template>

<script>
    import { Icon } from "@/components/icon/icon.js";
	import * as labelApi from '@/service/modules/label.js';
    export default {
        data() {
            return {
                loading: false,
				classifyList: [],
				editClassify: {
					sort: 0,
					title: '',
					classifyId: 0
				},
				editLabel: {
					id: 0,
					title: ''
				},
				modelRef: {
					classifyId: undefined,
					title: ''
				},
				searchData: {},
                showLabel: false,
                showLabelGroup: false,
                labelAddShowInput: null,
				showLabelEdit: false,
                labelSelectKeyList: [],
                labelSelectList: [],
                tagText: '',
                newLabel: [''],
                labelList: [],
                labelGroupName: '',
				sort: 0,
				pagination: {
					current: 1,
					pageSize: 20,
					totalCount: 0
				}
            }
        },
        components: { Icon },
        created() {
			this.getClassifyList();
			this.onSearch();
        },
        methods: {
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.modelRef));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await labelApi.getTagList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					title: this.searchData.title,
					classifyId: this.searchData.classifyId
				});
				this.loading = false;
				if (ret.code === 200) {
					this.labelList = ret.data.list;
					this.pagination.totalCount = ret.data.totalCount;
				}
			},
			async getClassifyList () {
				let ret = await labelApi.getTagClassifyList({});
				if (ret.code === 200) {
					this.classifyList = ret.data;
				}
			},
			async onEditClassify(classify) {
				this.loading = true;
				let ret = await labelApi.getTagClassifyDetail({
					id: classify.classifyId
				});
				this.loading = false;
				if (ret.code === 200) {
					this.editClassify = {
						title: ret.data.title,
						sort: ret.data.sort,
						id: ret.data.id
					}
					this.showLabelGroup = true;
				}
			},
			onDel(classify, label) {
				this.$confirm({
					content: '确定删除该标签吗?',
					onOk: async () => {
						this.loading = true;
						let ret = await labelApi.delTag({
							id: label.id
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
						}
					}
				})
			},
			onDelClassify(classify) {
				this.$confirm({
					content: '确定删除该分类吗?',
					onOk: async () => {
						this.loading = true;
						let ret = await labelApi.delTagClassify({
							id: classify.classifyId
						});
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.getData();
							this.getClassifyList();
						}
					}
				})
			},
			resetBtn() {
			  this.$refs.formRef.resetFields();
			  this.onSearch();
			},
            onShowModal() {
                this.getLabelList();
                this.showLabel = true;
                this.labelSelectKeyList = JSON.parse(JSON.stringify(this.value));
            },
            onAddNewLabelGroup: function () {
                this.labelGroupName = '';
                this.editClassify = {
					title: '',
					sort: 0,
					classifyId: 0
				};
                this.showLabelGroup = true;
            },
            onAddLabel: function (item, index) {
				this.editClassify = JSON.parse(JSON.stringify(item));
            },
            onLabelAddOk: async function () {
                let list = this.editClassify.tagList.filter((subItem) => {
                    return subItem.title == this.editLabel.title;
                });
                if(!this.editLabel.title || list.length > 0) {
                    this.$message.warn('请检查填入名称是否为空或存在重复标签');
                    return;
                }
				this.loading = true;
				let ret;
				if (this.editLabel.id) {
					ret = await labelApi.updateTag({
						title: this.editLabel.title,
						id: this.editLabel.id,
						classifyId: this.editClassify.classifyId
					});
				} else {
					ret = await labelApi.saveTag({
						title: this.editLabel.title,
						classifyId: this.editClassify.classifyId
					});
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.showLabelEdit = false;
					this.editLabel = {
						title: '',
						id: 0
					}
					this.editClassify = {
						title: '',
						classifyId: 0,
						sort: 0
					}
					this.labelAddShowInput = null;
					this.getData();
				}
            },
            onAddLabelGroupAddOk: async function () {
                if(!this.editClassify.title) {
                   this.$message.warn('请输入标签分类名称');
                    return;
                }
				this.loading = true;
				let ret;
				if (this.editClassify.id) {
					ret = await labelApi.updateTagClassify({
						type: 2,
						level:1,
						title: this.editClassify.title,
						sort: this.editClassify.sort,
						id: this.editClassify.id
					})
				} else {
					ret = await labelApi.saveTagClassify({
						type: 2,
						level:1,
						title: this.editClassify.title,
						sort: this.editClassify.sort
					})
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功');
					this.editClassify = {
						title: '',
						sort: 0
					}
					this.showLabelGroup = false;
					this.getClassifyList();
					this.getData();
				}
            },
            onLabelClose() {
                this.showLabel = false;
                this.labelSelectKeyList = JSON.parse(JSON.stringify(this.value));
                this.labelSelectList = [];
            },
			onEdit(classify, labelItem) {
				if (labelItem) {
					this.editLabel = JSON.parse(JSON.stringify(labelItem));
				} else {
					this.editLabel = {
						id: 0,
						title: ''
					}
				}
				this.editClassify = JSON.parse(JSON.stringify(classify));
				this.showLabelEdit = true;
			},
            onOk: function() {
                if (this.labelSelectKeyList.length === 0) {
                    return this.$message.warn('请至少选择一个标签');
                } else {
                    this.showLabel = false;
                    this.$emit('update:value', this.labelSelectKeyList);
                    this.$emit('change', this.labelSelectList);
                }
            },
			onPageChange(page) {
				this.pagination.current = page;
				this.getData();
			},
			onShowSizeChange(page, pageSize) {
				this.pagination.current = 1;
				this.pagination.pageSize = pageSize;
				this.getData();
			}
        }
    }
</script>

<style scoped>
.ui-form__item {
    display: flex;
    margin:10px 20px;
}
.ui-form__label {
    display: inline-block;
    width: 100px;
    padding-right: 10px;
    line-height: 32px;
    text-align: right;
}
.ui-department__selected {
    margin-top: 8px;
}
.ui-label__name {
    margin: 8px 0;
}
.ui-tag {
    line-height: 28px;
    height: auto;
	padding: 0 10px;
    margin-bottom: 5px;
    margin-right: 20px;
    border-radius: 0;
    background-color: transparent;
	cursor: pointer;
}
.ui-label__selectAction {
    color: #1890ff;
    background: #e7f7ff;
    border: 1px solid #1890ff;
}
.ui-label__box {
	display: flex;
	flex-wrap: wrap;
    margin: 0 20px;
}
.ui-labelGroup__item {
	padding: 10px;
	border-bottom: 1px dashed #EEE;
}
.ui-input {
	height: 30px;
}
</style>